import "core-js/modules/es.array.push.js";
import Tabbar from "@/components/tabbar";
import Dialog from '@/components/dialog';
export default {
  data() {
    return {
      showCenter: false,
      btnLogin: false,
      showDownBtn: false
    };
  },
  created() {
    if (process.env.VUE_APP_TYPE != 'app') {
      this.showDownBtn = true;
    }
  },
  methods: {
    goChushou() {
      if (this.userInfo.guquan_on == 0) {
        this.$toast("该功能暂未开放");
        return;
      } else {
        this.$router.push("/transfer");
      }
    },
    openOutPop() {
      this.showCenter = true;
    },
    goOut() {
      this.$store.dispatch("goOut");
      this.$router.replace("/");
    },
    openServer() {
      window.open(this.userInfo.serviceUrl);
    },
    downApp() {
      window.open(this.userInfo.appUrl);
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  components: {
    Tabbar,
    Dialog
  }
};